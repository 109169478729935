import { useForm } from "react-hook-form";
import {toast, ToastContainer} from "react-toastify";
import {Box, Button, Card, CardContent, Divider, TextField, Tooltip, Typography} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/styles";
import {Shortcut} from "@material-ui/icons";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles(() => ({
    input: {
        color: "#fff"
    },
}));

async function getSetting() {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/settings/Reraise`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("auth")}`
        }
    });
    return await response.json();
}

async function updateSetting(id, setting) {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/settings/${id}`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("auth")}`
        },
        body: JSON.stringify(setting)
    });
    return await response.json();
}

export const SettingsForm = (props) => {
/*    const globalSettings = {
        currentJackpot: 5,
        bookNowUrl: "https://google.com"
    }*/
    const navigate = useNavigate();
    const me = JSON.parse(localStorage.getItem("user"));
    const classes = useStyles();
    const [ setting, setSetting ] = useState({});
    const [ refresh, setRefresh ] = useState(true);

    useEffect(() => {
        getSetting().then(r => {
            if(!r._id) {
                toast.error("Errore durante la ricerca delle impostazioni");
                return;
            }
            setSetting(r);
            setValue("currentJackpot", r.currentJackpot);
            setValue("bookNowUrl", r.bookNowUrl.replaceAll("https://", ""));
            setValue("screenUrl", r.screenUrl.replaceAll("https://", ""));
            setValue("liveUrl", r.liveUrl.replaceAll("https://", ""));
            setValue("nextEventLink", r.nextEventLink.replaceAll("https://", ""));
        }).catch(err => toast.error("Errore"));
    }, [ refresh ]);

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm();

    function onSubmit(data) {
        if (!data.bookNowUrl.includes("https://")) {
            data.bookNowUrl = "https://" + data.bookNowUrl;
        }
        if (!data.screenUrl.includes("https://")) {
            data.screenUrl = "https://" + data.screenUrl;
        }
        if (!data.liveUrl.includes("https://")) {
            data.liveUrl = "https://" + data.liveUrl;
        }
        if (!data.nextEventLink.includes("https://")) {
            data.nextEventLink = "https://" + data.nextEventLink;
        }
        updateSetting(setting._id, { ...data }).then(r => {
            if (!r._id) {
                toast.error("Errore durante l'aggiornamento delle impostazioni");
                return;
            }
            setRefresh(!refresh);
            toast.success("Impostazioni aggiornate con successo");
        }).catch(err => toast.error("Errore"));
    }

    return (
        <div>
            <ToastContainer />
            {/* ------------------------------------------------------------------------------------------------ */}
            {/* Basic Checkbox */}
            {/* ------------------------------------------------------------------------------------------------ */}
            <Card
                variant="outlined"
                sx={{
                    p: 0,
                    backgroundColor: "#32363E",
                }}
            >
                <Box
                    sx={{
                        padding: "15px 30px",
                    }}
                    display="flex"
                    alignItems="center"
                >
                    <Box flexGrow={1} sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <Typography
                            sx={{
                                fontSize: "18px",
                                fontWeight: "500",
                                color: "#f58556"
                            }}
                        >
                            { setting?.agency ?? "" }: Modifica Impostazioni
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <CardContent
                    sx={{
                        padding: "30px",
                    }}
                >
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <TextField
                            {...register('currentJackpot', { required: true })}
                            id="currentJackpot"
                            label="Jackpot"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                className: classes.input,
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            variant="outlined"
                            fullWidth
                            sx={{
                                mb: 2,
                                width: "9%",
                                mr: "0.5%"
                            }}
                        />
                        <TextField
                            {...register('bookNowUrl')}
                            id="bookNowUrl"
                            label="Link prenota ora"
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                className: classes.input
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                                endAdornment: <InputAdornment position="start">
                                    <Tooltip arrow placement="top-start" title={`Vai a ${setting.bookNowUrl}`}>
                                        <Shortcut style={{ cursor: "pointer" }} onClick={() => { window.open(setting.bookNowUrl, "_blank") }} ></Shortcut>
                                    </Tooltip>
                                </InputAdornment>
                            }}
                            variant="outlined"
                            fullWidth
                            sx={{
                                mb: 2,
                                width: "44.5%",
                                ml: "0.5%"
                            }}
                        />
                        <TextField
                            {...register('nextEventLink')}
                            id="bookNowUrl"
                            label="Link prossimo evento"
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                className: classes.input
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                                endAdornment: <InputAdornment position="start">
                                    <Tooltip arrow placement="top-start" title={`Vai a ${setting.nextEventLink}`}>
                                        <Shortcut style={{ cursor: "pointer" }} onClick={() => { window.open(setting.nextEventLink, "_blank") }} ></Shortcut>
                                    </Tooltip>
                                </InputAdornment>
                            }}
                            variant="outlined"
                            fullWidth
                            sx={{
                                mb: 2,
                                width: "44.5%",
                                ml: "0.9%"
                            }}
                        />
                        <TextField
                            {...register('liveUrl')}
                            id="liveUrl"
                            label="Link live"
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                className: classes.input
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                                endAdornment: <InputAdornment position="start">
                                    <Tooltip arrow placement="top-start" title={`Vai a ${setting.liveUrl}`}>
                                        <Shortcut style={{ cursor: "pointer" }} onClick={() => { window.open(setting.liveUrl, "_blank") }} ></Shortcut>
                                    </Tooltip>
                                </InputAdornment>
                            }}
                            variant="outlined"
                            fullWidth
                            sx={{
                                mb: 2,
                                width: "49.5%",
                                mr: "0.5%"
                            }}
                        />
                        <TextField
                            {...register('screenUrl')}
                            id="bookNowUrl"
                            label="Link schermo"
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                className: classes.input
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                                endAdornment: <InputAdornment position="start">
                                    <Tooltip arrow placement="top-start" title={`Vai a ${setting.screenUrl}`}>
                                        <Shortcut style={{ cursor: "pointer" }} onClick={() => { window.open(setting.screenUrl, "_blank") }} ></Shortcut>
                                    </Tooltip>
                                </InputAdornment>
                            }}
                            variant="outlined"
                            fullWidth
                            sx={{
                                mb: 2,
                                width: "49.5%",
                                ml: "0.5%"
                            }}
                        />
                        <div>
                            <Box
                                m={1}
                                //margin
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="flex-end" >
                                <Button disabled={!me.roles.includes("ADMIN")}
                                        type="submit" color="primary" variant="contained">
                                    Salva
                                </Button>
                            </Box>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}
