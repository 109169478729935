import { lazy } from "react";
import { Navigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import UserEdit from "../views/User/edit/edit.user";
import Privacy from "../views/Privacy/Privacy";
import ResetPassword from "../views/ResetPassword/resetPassword";
import PageNotFound from "../views/PageNotFound/PageNotFound";
import SettingsPage from "../views/Settings/settings";
import CountersPage from "../views/Counters/counters";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
/****End Layouts*****/

/*****Pages******/
const Dashboard1 = lazy(() => import("../views/dashboards/Dashboard1"));
const Login = lazy(() => import("../views/Login/Login"));

/*****Tables******/
const BasicTable = lazy(() => import("../views/tables/BasicTable"));
const UserList = lazy(() => import("../views/User/list/user.list"));
// form elements
const ExAutoComplete = lazy(() =>
  import("../views/FormElements/ExAutoComplete")
);
const ExButton = lazy(() => import("../views/FormElements/ExButton"));
const ExCheckbox = lazy(() => import("../views/FormElements/ExCheckbox"));
const ExRadio = lazy(() => import("../views/FormElements/ExRadio"));
const ExSlider = lazy(() => import("../views/FormElements/ExSlider"));
const ExSwitch = lazy(() => import("../views/FormElements/ExSwitch"));
// form layouts
const FormLayouts = lazy(() => import("../views/FormLayouts/FormLayouts"));

/*****Routes******/

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="user/list" /> },
      // { path: "dashboards/dashboard1", exact: true, element: <PrivateRoute ><Dashboard1 /></PrivateRoute> },
      // { path: "table", element: <BasicTable /> },
      { path: "settings", exact: true, element: <PrivateRoute><SettingsPage /></PrivateRoute> },
      { path: "counters", exact: true, element: <PrivateRoute><CountersPage /></PrivateRoute> },
      { path: "user/list", exact: true, element: <PrivateRoute><UserList /></PrivateRoute> },
      { path: "user/:id", exact: true, element: <PrivateRoute><UserEdit /></PrivateRoute> },
      // { path: "product/:id", exact: true, element: <PrivateRoute><ProductEdit /></PrivateRoute> },
      { path: "/form-layouts/form-layouts", element: <FormLayouts /> },
      // { path: "/form-elements/autocomplete", element: <ExAutoComplete /> },
      // { path: "/form-elements/button", element: <ExButton /> },
      // { path: "/form-elements/checkbox", element: <ExCheckbox /> },
      // { path: "/form-elements/radio", element: <ExRadio /> },
      // { path: "/form-elements/slider", element: <ExSlider /> },
      // { path: "/form-elements/switch", element: <ExSwitch /> },
    ],
  },
  {
    path: "auth/",
    children: [
      { path: "login", exact:true, element: <Login /> },
      { path: "reset-password/:resetCode", exact:true, element: <ResetPassword /> },
    ]
  },
  {
    path: "privacy.html",
    exact: true,
    element: <Privacy />
  },
  {
    path: "*",
    element: <PageNotFound />
  }
];

export default ThemeRoutes;
