import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import "./resetPassword.css"
import LogoIcon from "../../layouts/FullLayout/Logo/LogoIcon";
import { useParams } from "react-router-dom";

function ResetPassword() {
    //const navigate = useNavigate();
    useEffect(() => {
        document.title = "Reset Password | Reraise Events";
    }, []);

    return(
        <div>
            <ToastContainer />
            <div className="container">
                <div className="header">
                    <div className="header-image">
                        <LogoIcon />
                    </div>
                </div>
                    {BodyReset()}
            </div>
        </div>
    )
}

const BodyReset = () => {
    const { resetCode } = useParams();
    const [result, setResult] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = async (data) => {
        if(data.password !== data.confirmPassword) {
            toast.error("Le password non corrispondono");
            return;
        }
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/auth/reset-password/${resetCode}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "newPassword": data.password
            }),
        });
        const result = await response.json();
        if(result.error) {
            toast.error(result.message);
            return;
        }
        setResult(true);
    };
    if (!result) {
        return (
            <div>
                <h1 className="header-title text-center">
                    Reset Password
                </h1>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input app-input">
                        <input {...register('password', { required: true })} type="password" placeholder="Nuova Password"/>
                        {errors.password && <p>Il campo è richiesto</p>}
                    </div>

                    <div className="input app-input">
                        <input {...register('confirmPassword', { required: true })} type="password" placeholder="Conferma Password"/>
                        {errors.confirmPassword && <p>Il campo è richiesto</p>}
                    </div>

                    <button type="submit" className="button app-login-button">
                        Reset
                    </button>
                </form>
            </div>
        );
    } else {
        return (
            <div>
                <h1 className="header-title text-center">
                    Password modificata correttamente.
                </h1>
            </div>
        );
    }
}

export default ResetPassword;
