import React, { useEffect } from "react";
import "./PageNotFound.css";

function PageNotFound() {
    useEffect(() => {
        document.title = "Page Not Found | Reraise Events";
    }, []);

    return (
        <div id="main">
            <div className="fof">
                <h1>404</h1><br/>
                <h1 className="fof-animation">Pagina non trovata</h1>
            </div>
        </div>
    );
}

export default PageNotFound;
