import { Typography } from "@material-ui/core";
import React from "react";

export const TypographyCounterComponent = (props) => {

    return (
        <Typography sx={{ display: "flex" }} variant="h4">
            <div style={{ width: "10%", textAlign: "center" }} >
                { props.title }
            </div>
            <div style={{ color: "white", marginLeft: "2em" }} >{ props.children }</div>
        </Typography>
    );
}
