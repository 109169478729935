import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import 'react-toastify/dist/ReactToastify.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Card,
    CardContent,
    Divider,
    Box,
    Typography,
    TextField,
    // FormControlLabel,
    // Checkbox,
    Button,
    MenuItem,
    Accordion,
    AccordionSummary, AccordionDetails, Tooltip,
    // Grid,
    // RadioGroup,
    // Radio,
    // FormControl,
    // MenuItem,
} from "@material-ui/core";
import {useNavigate, useParams} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import { makeStyles } from "@material-ui/styles";
import FileDownloadIcon from '@material-ui/icons/FileDownload';
import PersonIcon from "@material-ui/icons/Person";
import ManageAccountsIcon from "@material-ui/icons/ManageAccounts";
import FilterListIcon from "@material-ui/icons/FilterList";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import {DesktopDatePicker, LocalizationProvider} from "@material-ui/lab";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles(() => ({
    input: {
        color: "#fff"
    },
}));

const listDocument = [
    {value: "IDENTITY", label: "Carta di Identità"},
    {value: "DRIVER_LICENSE", label: "Patente"},
    {value: "PASSPORT", label: "Passaporto"},
    {value: "FIREARMS_LICENSE", label: "Porto d'armi"},
];

const eventBus = {
    on(event, callback) {
        document.addEventListener(event, (e) => callback(e.detail));
    },
    dispatch(event, data) {
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    remove(event, callback) {
        document.removeEventListener(event, callback);
    },
};

async function getUser(id) {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/${id}`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("auth")}`
        },
    });
    return await response.json();
}

async function updateData(user) {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/${user._id}`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("auth")}`
        },
        body: JSON.stringify(user),
    });
    return await response.json();
}

async function getPdf(userId) {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/${userId}/pdf`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("auth")}`
        }
    });
    response.json().then(r => window.open(`${process.env.REACT_APP_SERVER_URL}/${r.path}`));
}

function formatDate(date) {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}


function ContainerForm() {
    const [error, setError] = useState(false);
    const [form, setForm] = useState("principal");
    eventBus.on("error", () => {
        setError(true);
    });
    eventBus.on("changeForm", (detail) => {
        setForm(detail);
    });
    if(error) {
        return (<h1>Utente non trovato</h1>)
    } else {
        switch (form) {
            case "principal": return (<UserForm form={form} />);
            case "roles": return (<RolesForm form={form} />);
            case "rc": return (<RcForm form={form} />);
        }
    }

}

export default ContainerForm;


const UserForm = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    const listGender = [
        {value: "M", label: "Uomo"},
        {value: "F", label: "Donna"},
    ];
    const me = JSON.parse(localStorage.getItem("user"));
    const [roles, setRoles] = useState([]);
    const [documentType, setDocumentType] = useState("");
    const [ active, setActive ] = useState(false);
    const [ refresh, setRefresh ] = useState(true);
    const [ birthDate, setBirthDate ] = useState();

    useEffect(async () => {
        const user = await getUser(id);
        if(!user?._id) {
            eventBus.dispatch("error");
            return;
        }
        // options: { shouldValidate: true, shouldDirty: true, shouldTouch: true}
        // const birthDate = new Date(user.birthDate);
        // console.log(`${birthDate.getDate()}/${birthDate.getMonth() + 1}/${birthDate.getFullYear()}`)
        //Password from
        setValue2("_id", user._id);
        //////////
        setValue("_id", user._id);
        setValue("cardNumber", user.cardNumber);
        setValue("username", user.username);
        setValue("firstName", user.firstName);
        setValue("lastName", user.lastName);
        //setValue("gender", user.gender);
        setValue("cf", user.cf);
        setValue("email", user.email);
        //setValue("password", user.password);
        setValue("notes", user.notes);
        setValue("phone", user.phone);
        setBirthDate(new Date(user.birthDate).toISOString());
        setValue("birthDate", formatDate(new Date(user.birthDate)));
        setValue("birthPlace", user.birthPlace);
        setActive(user.active);
        setValue("address", {
            city: user.address?.city,
            country: user.address?.country,
            province: user.address?.province,
            secondary: user.address?.secondary,
            street: user.address?.street,
            zip: user.address?.zip,
        });
        setDocumentType(user.documents[0]?.type);
        setValue("documents", [{
            expiresAt: user.documents[0]?.expiresAt,
            number: user.documents[0]?.number,
            type: user.documents[0]?.type
        }]);
        setRoles(user.roles);
    }, [id, props.form, refresh]);

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const {
        register: register2,
        setValue: setValue2,
        handleSubmit: handleSubmit2
    } = useForm();

    async function activateUser() {
        await fetch(`${process.env.REACT_APP_SERVER_URL}/users/activate/${id}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("auth")}`
            },
        }).then(res => res.json()).then((res) => {
            if (!res) {
                return;
            }
            setRefresh(!refresh);
        });
    }

    async function deActivateUser() {
        await fetch(`${process.env.REACT_APP_SERVER_URL}/users/${id}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("auth")}`
            },
            body: JSON.stringify({ active: false })
        }).then(res => res.json()).then((res) => {
            if (!res) {
                return;
            }
            setRefresh(!refresh);
        });
    }

        return (
            <div>
                <ToastContainer />
                {/* ------------------------------------------------------------------------------------------------ */}
                {/* Basic Checkbox */}
                {/* ------------------------------------------------------------------------------------------------ */}
                <Card
                    variant="outlined"
                    sx={{
                        p: 0,
                        backgroundColor: "#32363E",
                    }}
                >
                    <Box
                        sx={{
                            padding: "15px 30px",
                        }}
                        display="flex"
                        alignItems="center"
                    >
                        <Box flexGrow={1} sx={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <Typography
                                sx={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    color: "#f58556"
                                }}
                            >
                                Dettaglio Utente
                            </Typography>
                            <Typography sx={{
                                fontSize: "18px",
                                fontWeight: "500",
                                color: "#fff",
                                cursor: "pointer",
                            }}>
                                <Tooltip placement="top-start" title="Gestisci ruoli">
                                    <ManageAccountsIcon sx={{fill: "#f58556", cursor: "pointer", marginRight: "15px"}} onClick={() => eventBus.dispatch("changeForm", "roles")} />
                                </Tooltip>

                                <Tooltip placement="top-start" title="Gestisci Premi">
                                    <FilterListIcon sx={{fill: "#f58556", cursor: "pointer", marginRight: "15px"}} onClick={() => eventBus.dispatch("changeForm", "rc")} />
                                </Tooltip>

                                <Tooltip placement="top-start" title="Esporta scheda cliente">
                                    <FileDownloadIcon sx={{fill: "#f58556", cursor: "pointer"}} onClick={() => getPdf(id)} />
                                </Tooltip>
                            </Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <CardContent
                        sx={{
                            padding: "30px",
                        }}
                    >
                        <form key={1} onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                {...register('cardNumber', { required: true })}
                                id="id"
                                label="Numero Tessera"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.input,
                                    readOnly: Boolean(true),
                                }}
                                fullWidth
                                sx={{
                                    mb: 2,
                                    mr: "0.5%",
                                    width: "49%"
                                }}
                            />
                            <TextField
                                {...register('_id', { required: true })}
                                id="id"
                                label="Codice Tessera"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.input,
                                    readOnly: Boolean(true),
                                }}
                                fullWidth
                                sx={{
                                    mb: 2,
                                    ml: "0.5%",
                                    width: "50%"
                                }}
                            />
                            <TextField
                                {...register('firstName')}
                                label="Nome"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.input,
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    mb: 2,
                                    mr: "0.3%",
                                    width: "33%"
                                }}
                            />
                            <TextField
                                {...register('lastName', { required: "ok" })}
                                id="lastName"
                                label="Cognome"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.input,
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    mb: 2,
                                    ml: "0.3%",
                                    width: "33%"
                                }}
                            />
                            <TextField
                                {...register('username')}
                                id="outlined-password-input"
                                label="Username"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.input,
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    mb: 2,
                                    ml: "0.3%",
                                    width: "33%"
                                }}
                            />
                            <TextField
                                {...register('email')}
                                id="outlined-password-input"
                                label="Email"
                                type="email"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.input,
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    mb: 2,
                                    mr: "0.5%",
                                    width: "49%"
                                }}
                            />
                            <TextField
                                {...register('phone')}
                                id="phone"
                                label="Telefono"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.input,
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    mb: 2,
                                    ml: "0.5%",
                                    width: "50%"
                                }}
                            />
                            <TextField
                                select
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.input
                                }}
                                sx={{
                                    mb: 2,
                                    mr: "0.5%",
                                    width: "49%"
                                }}
                                value={documentType}
                                onChange={(e) => {
                                    setDocumentType(e.target.value);
                                    setValue("documents[0].type", e.target.value);
                                }}
                                label="Tipologia Documento"
                                id="documentType"
                            >
                                {listDocument.map((person) => (
                                    <MenuItem key={person.value} value={person.value}>
                                        {person.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                {...register('documents[0].number', { required: true })}
                                id="documentNumber"
                                label="Numero Documento"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.input
                                }}
                                sx={{
                                    mb: 2,
                                    ml: "0.5%",
                                    width: "50%"
                                }}
                            />
                            <TextField
                                {...register('birthPlace')}
                                id="birthPlace"
                                label="Luogo di Nascita"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.input,
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    mb: 2,
                                    mr: "0.3%",
                                    width: "33%"
                                }}
                            />
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    InputProps={{ className: classes.input }}
                                    label="Nati da"
                                    inputFormat="dd/MM/yyyy"
                                    value={birthDate}
                                    sx={{
                                        ".MuiInputBase-root": {
                                            borderRadius: "10px",
                                        },
                                        ".MuiFilledInput-input": {
                                            padding: "16.5px 14px",
                                        }
                                    }}
                                    onChange={(value) => {
                                        const newBirthDate = formatDate(new Date(value));
                                        setBirthDate(value.toISOString());
                                        setValue("birthDate", newBirthDate);
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        {...register('birthDate')}
                                        id="birthDate"
                                        label="Data di Nascita"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            className: classes.input,
                                        }}
                                        helperText={null}
                                        variant="outlined"
                                        fullWidth
                                        sx={{
                                            mb: 2,
                                            mr: "0.3%",
                                            width: "33%"
                                        }}
                                    />}
                                />
                            </LocalizationProvider>
                            <TextField
                                {...register('cf')}
                                id="cf"
                                label="Codice Fiscale"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.input,
                                    // readOnly: Boolean(true),
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    mb: 2,
                                    mr: "0.3%",
                                    width: "33%"
                                }}
                            />
                            <TextField
                                {...register('address.city')}
                                id="city"
                                label="Città"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.input,
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    mb: 2,
                                    ml: "0.3%",
                                    width: "33%"
                                }}
                            />
                            <TextField
                                {...register('address.street')}
                                id="street"
                                label="Indirizzo"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.input,
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    mb: 2,
                                    ml: "0.3%",
                                    width: "33%"
                                }}
                            />
                            <TextField
                                {...register('address.secondary')}
                                id="secondary"
                                label="Indirizzo 2°"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.input,
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    mb: 2,
                                    ml: "0.3%",
                                    width: "33%"
                                }}
                            />
                            <TextField
                                {...register('address.zip')}
                                id="zip"
                                label="Cap"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.input,
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    mb: 2,
                                    ml: "0.5%",
                                    width: "20%"
                                }}
                            />
                            <TextField
                                {...register('notes')}
                                id="notes"
                                label="Note aggiuntive"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.input,
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    mb: 2,
                                    ml: "0.5%",
                                    width: "79%"
                                }}
                            />
                            <Box
                                m={1}
                                //margin
                                display="flex"
                                justifyContent="space-between"
                                alignItems="flex-end" >
                                <Button sx={{m: 1}}
                                        type="button"
                                        color="warning"
                                        variant="contained"
                                        onClick={active ? deActivateUser : activateUser}
                                >
                                    { active ? "Disattiva": "Attiva" }
                                </Button>
                                <Button sx={{m: 1}}
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        disabled={me.roles.includes("STAFF") && (roles.includes("ADMIN") || roles.includes("STAFF"))}
                                >
                                    Salva
                                </Button>
                            </Box>
                        </form>
                        <form key={2} onSubmit={handleSubmit2(onSubmitPassword)}>
                            <Accordion expanded={checked} onChange={() => setChecked(!checked)} sx={{ mb:2, backgroundColor: "#2A3037" }} >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{fill: "#fff"}} />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0, color: "#fff" }}>
                                        Imposta nuova password
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ color: "black" }} >
                                        <TextField
                                            {...register2('password', { required: true })}
                                            id="password"
                                            label="Nuova Password"
                                            type="password"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                className: classes.input,
                                                form: {
                                                    autocomplete: 'off',
                                                },
                                            }}
                                            variant="outlined"
                                            fullWidth
                                            sx={{
                                                mb: 2,
                                            }}
                                        />
                                        <TextField
                                            {...register2('confirmPassword', { required: true })}
                                            id="password"
                                            label="Conferma Password"
                                            type="password"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                className: classes.input,
                                                form: {
                                                    autocomplete: 'off',
                                                },
                                            }}
                                            variant="outlined"
                                            fullWidth
                                            sx={{
                                                mb: 2,
                                            }}
                                        />
                                        <Box
                                            m={1}
                                            //margin
                                            display="flex"
                                            justifyContent="flex-end"
                                            alignItems="flex-end"
                                        >
                                            <Button sx={{ mb: 2 }} type="submit" color="primary" variant="contained">
                                                Salva
                                            </Button>
                                        </Box>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </form>
                    </CardContent>
                </Card>
            </div>
        );

    async function onSubmit(data) {
        const result = await updateData({...data, birthDate});
        if(!result?._id) {
            toast.error(result.error);
            return;
        }
        toast.success("Operazione completata.");
        if(checked) {
            setChecked(!checked);
        } else {
            setTimeout(() => navigate("/user/list"),5000);
        }
    }

    async function onSubmitPassword(data) {
        if(data.password !== data.confirmPassword) {
            toast.error("Le password non corrispondono");
            return;
        }
        const result = await updateData({_id: data._id, password: data.password});
        if(!result?._id) {
            toast.error(result.error);
            return;
        }
        toast.success("Operazione completata.");
    }


};

const RolesForm = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const classes = useStyles();
    const [roles, setRoles] = useState("");
    const listGender = [
        {value: "M", label: "Uomo"},
        {value: "F", label: "Donna"},
    ];
    const me = JSON.parse(localStorage.getItem("user"));

    useEffect(async () => {
        const user = await getUser(id);
        if(!user?._id) {
            eventBus.dispatch("error");
            return;
        }
        setValue("_id", user._id);
        if(user.roles.includes("ADMIN")) {
            setValue("roles", "ADMIN");
            setRoles("ADMIN");
        } else if(user.roles.includes("STAFF")) {
            setValue("roles", "STAFF");
            setRoles("STAFF");
        } else {
            setValue("roles", "USER");
            setRoles("USER");
        }
    }, [id, props.form]);

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const allRoles = [
        {
            value: "USER",
            label: "USER",
        },
        {
            value: "STAFF",
            label: "STAFF",
        }
        // {
        //     value: "ADMIN",
        //     label: "ADMIN",
        // },
    ];

    async function promoteUserToStaff(data) {
        const updated = {};
        if(data.roles === "STAFF") {
            updated.roles = ['USER', 'STAFF'];
            updated.rank = "STAFF";
        } else {
            updated.roles = ['USER'];
            updated.rank = "SILVER";
        }
        const res = await (await fetch(`${process.env.REACT_APP_SERVER_URL}/users/${data._id}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("auth")}`
            },
            body: JSON.stringify({roles: updated.roles, rank: updated.rank})
        })).json();

        if (!res._id) {
            return toast.error(res.message);
        }
        toast.success("Operazione completata.");

    }

    return (
        <div>
            <ToastContainer />
            {/* ------------------------------------------------------------------------------------------------ */}
            {/* Basic Checkbox */}
            {/* ------------------------------------------------------------------------------------------------ */}
            <Card
                variant="outlined"
                sx={{
                    p: 0,
                    backgroundColor: "#32363E",
                    width: "100%"
                }}
            >
                <Box
                    sx={{
                        padding: "15px 30px",
                    }}
                    display="flex"
                    alignItems="center"
                >
                    <Box flexGrow={1} sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <Typography
                            sx={{
                                fontSize: "18px",
                                fontWeight: "500",
                            }}
                        >
                            Gestione ruoli
                        </Typography>
                        <Typography sx={{
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "#fff",
                            cursor: "pointer",
                        }}>
                            <Tooltip placement="top-start" title="Scheda utente">
                                <PersonIcon sx={{fill: "#f58556", cursor: "pointer", marginRight: "15px"}} onClick={() => eventBus.dispatch("changeForm", "principal")}/>
                            </Tooltip>
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <CardContent
                    sx={{
                        padding: "30px",
                    }}
                >
                    <form onSubmit={handleSubmit(promoteUserToStaff)} >
                        <TextField
                            select
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                className: classes.input,
                            }}
                            sx={{
                                mb: 2
                            }}
                            value={roles}
                            onChange={(e) => {
                                setRoles(e.target.value);
                                setValue("roles", e.target.value);
                            }}
                            label="Ruolo"
                            id="roles"
                            fullWidth
                        >
                            {allRoles.map((person) => (
                                <MenuItem key={person.value} value={person.value}>
                                    {person.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <div>
                            <Button disabled={!me.roles.includes("ADMIN")}
                                type="submit" color="primary" variant="contained">
                                Assegna Ruolo
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
};

const RcForm = (props) => {
    const { id } = useParams();
    const classes = useStyles();
    const me = JSON.parse(localStorage.getItem("user"));
    const [ info, setInfo ] = useState({ tickets: 0, coins: 0 });
    const [ amount, setAmount ] = useState("");
    const [ refresh, setRefresh ] = useState(false);

    useEffect(async () => {
        const user = await getUser(id);
        if (!user?._id) {
            eventBus.dispatch("error");
            return;
        }
        setInfo({ tickets: user?.tickets, coins: user?.coins });
    }, [ refresh ])

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    function handleSetAction(event, action) {
        event.preventDefault();

        updateTickets(action);
    }

    function handleChangeAmount(event) {
        setAmount(event.target.value);
    }

    async function updateTickets(action) {
        const res = await (await fetch(`${process.env.REACT_APP_SERVER_URL}/users/${id}/tickets`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("auth")}`
            },
            body: JSON.stringify({ amount: action  === "up" ? amount : -amount })
        })).json();

        if (!res._id) {
            return toast.error(res.message);
        }
        setAmount("");
        toast.success("Operazione completata.");
        setRefresh(!refresh);

    }

    async function subCoin(data) {
        console.log(data)
        const res = await (await fetch(`${process.env.REACT_APP_SERVER_URL}/users/${id}/coins/sub`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("auth")}`
            },
            body: JSON.stringify(data)
        })).json();

        if (!res._id) {
            return toast.error(res.message);
        }
        toast.success("Operazione completata.");
        setRefresh(!refresh);

    }

    return (
        <div>
            <ToastContainer />
            {/* ------------------------------------------------------------------------------------------------ */}
            {/* Basic Checkbox */}
            {/* ------------------------------------------------------------------------------------------------ */}
            <Card
                variant="outlined"
                sx={{
                    p: 0,
                    backgroundColor: "#32363E",
                    width: "100%"
                }}
            >
                <Box
                    sx={{
                        padding: "15px 30px",
                    }}
                    display="flex"
                    alignItems="center"
                >
                    <Box flexGrow={1} sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <Typography
                            sx={{
                                fontSize: "18px",
                                fontWeight: "500",
                                color: "#f58556",
                            }}
                        >
                            Gestione Premi
                        </Typography>
                        <Typography sx={{
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "#fff",
                            cursor: "pointer",
                        }}>
                            <Tooltip placement="top-start" title="Scheda utente">
                                <PersonIcon sx={{fill: "#f58556", cursor: "pointer", marginRight: "15px"}} onClick={() => eventBus.dispatch("changeForm", "principal")}/>
                            </Tooltip>
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <CardContent
                    sx={{
                        padding: "30px",
                    }}
                >
                    <form onSubmit={handleSubmit(subCoin)} >
                        <Typography variant="h4" gutterBottom sx={{ mb: 2, display: "flex" }} >
                            Reraise Coin: <div style={{ color: "white", marginLeft: "0.5em" }} >{ info.coins }</div>
                        </Typography>
                        <TextField
                            {...register('amount', { required: true })}
                            id="amount"
                            label="Ammontare"
                            type="number"
                            required={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                className: classes.input,
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                // startAdornment: <InputAdornment position="start">
                                //     <Typography variant="h5" gutterBottom>
                                //         Ammontare:
                                //     </Typography>
                                // </InputAdornment>,
                                endAdornment: <InputAdornment position="start">
                                    <Button disabled={!me.roles.includes("ADMIN")} sx={{ width: "82px" }}
                                            type="submit" color="primary" variant="outlined">
                                        Scarica
                                    </Button>
                                </InputAdornment>
                            }}
                            sx={{
                                mb: 4
                            }}
                        />
                    </form>
                    <div >
                        <Typography variant="h4" gutterBottom sx={{ mb: 2, display: "flex" }} >
                            Tickets: <div style={{ color: "white", marginLeft: "0.5em" }} >{ info.tickets }</div>
                        </Typography>
                        <TextField
                            id="amount"
                            label="Ammontare"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={amount}
                            onChange={(event) => handleChangeAmount(event)}
                            inputProps={{
                                className: classes.input,
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="start">
                                    <Button onClick={(event) => handleSetAction(event,"up")}
                                            variant="outlined"
                                            href="#outlined-buttons"
                                            type="button"
                                            sx={{ mr: "10px", width: "82px" }} >
                                        Carica
                                    </Button>
                                    <Button onClick={(event) => handleSetAction(event, "down")}
                                            variant="outlined"
                                            type="button"
                                            href="#outlined-buttons"
                                            sx={{ width: "82px" }} >
                                        Scarica
                                    </Button>
                                </InputAdornment>,
                            }}
                            sx={{
                                mb: 2
                            }}
                        />
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};
