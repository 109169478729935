import React from "react";

import {Card, CardContent, Box, Grid} from "@material-ui/core";

import "./settings.css";
import {SettingsForm} from "./form/settings.form";

const SettingsPage = () => {
    return (
        <Grid container spacing={0}>
            <Grid item lg={12} md={12} xs={12}>
                <SettingsForm />
            </Grid>
        </Grid>
    );
};

export default SettingsPage;
