import React, { useEffect } from "react";
import LogoIcon from "../../layouts/FullLayout/Logo/LogoIcon";
import "./Privacy.css";

function Privacy() {
    useEffect(() => {
        document.title = "Privacy Policy | Reraise Events";
    }, []);
    return(
        <div className="privacy-container">
            <div className='flex-center'>
                <LogoIcon />
                    <h1>PRIVACY POLICY</h1>
            </div>
            <h2>1. Come raccogliamo le tue informazioni</h2>
            <p>
                Quando ti registri per un account Reraise, tramite il form accessibile dall'applicativo mobile, ti
                chiediamo alcune informazioni come il tuo nome utente, nome anagrafico reale, data di nascita, luogo di
                nascita, indirizzo e-mail.
                Queste informazioni sono strettamente private e utili al solo fine di tesseramento allo scopo di
                accedere ai servizi delle sale Reraise. Non pubblicheremo mai i tuoi dati privati, che conserviamo
                unicamente in maniera criptata nei nostri database.
                Le tue informazioni di contatto, come l'indirizzo email o il numero di telefono, potranno essere
                utilizzate per eventuali comunicazioni commerciali e informative inerenti al solo servizio Reraise. È
                sempre possibile, in ogni caso, aggiornare le tue preferenze di sottoscrizione in modo tale da essere
                escluso da queste ultime.
            </p>
            <h2>2. Informazioni analitiche</h2>
            <p>
                Possiamo raccogliere direttamente dati analitici, o utilizzare strumenti e servizi analitici di terze
                parti, per aiutarci a misurare il traffico e le tendenze di utilizzo del servizio.
                Questi strumenti raccolgono informazioni inviate dal tuo dispositivo, come le pagine che visiti, quanto
                spesso le visiti e altre informazioni che ci aiutano a migliorare il servizio.
                Raccogliamo e utilizziamo queste informazioni analitiche in forma aggregata, in modo che non possano
                essere ragionevolmente manipolate per identificare un particolare utente individuale.
            </p>
            <h2>3. Informazioni sui file di log</h2>
            <p>
                Le informazioni dei file di log sono riportate automaticamente dal tuo dispositivo ogni volta che accedi
                al servizio.
                Quando si utilizza il nostro servizio, i nostri server registrano automaticamente alcune informazioni
                anonime, tra cui i dettagli della tua richiesta, l'indirizzo Internet Protocol ("IP"), la pagina di
                riferimento/uscita, le pagine di destinazione, le pagine visualizzate e altre informazioni simili.
            </p>
            <h2>4. Identificatori del dispositivo</h2>
            <p>
                Quando l'utente accede al servizio da o attraverso un dispositivo mobile, possiamo raccogliere,
                monitorare e/o memorizzare a distanza uno o più "identificatori del dispositivo", come un identificatore
                universalmente univoco ("UUID").
                Gli identificatori del dispositivo sono piccoli file di dati o strutture dati memorizzati e associati al
                tuo dispositivo mobile. Questi identificatori sono utilizzati per inviare notifiche mirate allo scopo di
                migliorare la tua esperienza d'uso del servizio.
                Questi identificatori cambiano a cadenza mensile, allo scopo di ridurre una possibile <i>attack
                surface</i>.
            </p>
            <h2>5. Contatti</h2>
            <p>
                Siamo a disposizione per chiarire qualsiasi dubbio tu possa avere in merito al trattamento dei tuoi dati
                personali all'interno del nostro sistema.
                Contattaci all'indirizzo email: <b>info@reraiseevents.com</b>
            </p>
        </div>
    );
}

export default Privacy;
