import {ToastContainer} from "react-toastify";
import {Box, Card, CardContent, Divider, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/styles";
import {useNavigate} from "react-router-dom";
import {TypographyCounterComponent} from "../components/TypographyCounter/typography-counter.components";

const useStyles = makeStyles(() => ({
    input: {
        color: "#fff"
    },
}));

async function getCounters() {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/counters`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("auth")}`
        },
    });
    const result = await response.json();
    return await Promise.resolve(result);
}

export const CountersView = (props) => {
    const navigate = useNavigate();
    const me = JSON.parse(localStorage.getItem("user"));
    const classes = useStyles();
    const [counterMap, setCounterMap] = useState({});

    useEffect(async () => {
        const totalRc = await getCounters();
        setCounterMap(totalRc);
    }, [])

    return (
        <div>
            <ToastContainer />
            {/* ------------------------------------------------------------------------------------------------ */}
            {/* Basic Checkbox */}
            {/* ------------------------------------------------------------------------------------------------ */}
            <Card
                variant="outlined"
                sx={{
                    p: 0,
                    backgroundColor: "#32363E",
                }}
            >
                <Box
                    sx={{
                        padding: "15px 30px",
                    }}
                    display="flex"
                    alignItems="center"
                >
                    <Box flexGrow={1} sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <Typography
                            sx={{
                                fontSize: "18px",
                                fontWeight: "500",
                                color: "#f58556"
                            }}
                        >
                            Visualizza Informazioni
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <CardContent
                    sx={{
                        padding: "30px",
                        color: "#f58556"
                    }}
                >
                    {
                        counterMap?.rc ? <div>
                            <TypographyCounterComponent title={"RC Attuali:"}>{ counterMap.rc?.current }</TypographyCounterComponent>
                            <TypographyCounterComponent title={"RC Globali:"}>{ counterMap.rc?.global }</TypographyCounterComponent>
                            <TypographyCounterComponent title={"Tickets Globali:"}>{ counterMap.tickets?.total  }</TypographyCounterComponent>
                        </div> : null
                    }
                </CardContent>
            </Card>
        </div>
    );
}
