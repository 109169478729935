import React from "react";

import { Grid } from "@material-ui/core";
import ContainerForm from "./form/form.user";


const UserEdit = () => {
    return (
        <Grid container spacing={0}>
            <Grid item lg={12} md={12} xs={12}>
                <ContainerForm />
            </Grid>
        </Grid>
    );
};

export default UserEdit;
