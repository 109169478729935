import React from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { baseTheme } from './assets/global/Theme-variable'
import Themeroutes from "./routes/Router";
import "./index.css";

const App = () => {
    const routing = useRoutes(Themeroutes);
    const theme = baseTheme;
    return (
        <ThemeProvider theme={theme}>
            {routing}
        </ThemeProvider>
    );
};

export default App;
