import React, {useEffect} from "react";
import {Navigate, useNavigate} from "react-router-dom";

export default function PrivateRoute({children, ...rest}) {
    const navigate = useNavigate();
    const auth = localStorage.getItem("auth");
    useEffect(async () => {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/auth/me`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth}`,
            },
        });
        const result = await response.json();
        if (result?.message === "Unauthorized") {
            localStorage.clear();
            navigate("/auth/login");
            return;
        }
        if (!result?.roles.includes("ADMIN") && !result.roles.includes("STAFF")) {
            localStorage.clear();
            navigate("/auth/login");
            return;
        }
        localStorage.setItem("user", JSON.stringify(result))
    }, [auth])

    return (
        auth ? (
            children
        ) : (
            <Navigate
                to={{
                    pathname: "/auth/login"
                }}
            />
        )
    );
}
